<template>
  <div class="loan_item">
    <div class="inner inner0_5">
      <p>MicroLoan is your trusted online loan, providing users
        with a safe way to borrow money and solve urgently
        needed money. Use the money you can easily earn in
        the future to solve the problems of the present.</p>
    </div>
    <div class="inner inner1">
      <!-- <h4>Ventajas</h4> -->
      <div class="content">
        <h1>About Us</h1>
        <div class="left_cell">
          <p class="inner1_text">MicroLoan belongs to the Dolphin Lending Investor, Inc. is an online loan application. You can get safe and flexible
            loans through MicroLoan. You can choose a variety of methods to receive the loan amount. You can also pay your
            loan through multiple channels and there are no hidden charges. Your information is secure with us and used for
            verification only. We will determine the loan amount through the risk assessment of your information and credit evaluation.
          </p>
        </div>
      </div>
    </div>
    <div class="inner inner2">
      <!-- <h4>Sobre nosotros</h4> -->
      <div class="content">
        <div class="left_cell">
          <h1>Mission&Vision</h1>
          <p>To open the inclusion of short-term digital credit to
            the unbanked & general population for Philippines .</p>
          <p>
            To allow customers currently not being served by
            traditional banks to become active in the microcredit
            ecosystem and enable them to build a credit identity;
            in an easy, trustworthy & fast manner.
          </p>
        </div>
        <div class="right_cell">
          <img src="@/assets/images/loanImg/about_inner2.png" alt="">
        </div>
      </div>
    </div>
    <div class="inner inner3">
      <div class="content">
        <div class="right_cell">
          <img src="@/assets/images/loanImg/about_inner3.png" alt="">
        </div>
        <div class="left_cell">
          <h1>Information Disclosure</h1>
          <p>COMPANY NAME: Dolphin Lending Investor, Inc.</p>
          <p>SEC Registration No. CS201911897</p>
          <p>REGISTERED BUSINESS NAME: Microloan</p>
          <p>Authorization Certificate No. 3037</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.loan_item{
  .inner{
    min-width: 1100px;
    margin: 0 auto;
    overflow: hidden;
    h3{
      font-size: 18px;
      color: #6E60F9;
    }
    .content{
      padding: 0px calc((100% - 1100px) / 2) 0;
      display: flex;
    }
    .left_cell{
      float: left;
      padding: 60px 0;
      text-align: left;
      h1{
        font-size: 40px;
        margin-bottom: 30px;
      }
      p{
        font-size: 12px;
        line-height: 30px;
        color: #999999;
      }
      .cell_item{
        width: 267px;
        height: 140px;
        margin-top: 18px;
        border-bottom: 1px solid #6E60F9;
      }
    }
  }
  .inner0_5{
    background: url('~@/assets/images/loanImg/abouts_bg.png') no-repeat;
    background-size: 100% 100%;
    padding: 100px calc((100% - 1100px) / 2);
    p{
      font-size: 15px;
      font-family: Arial-Regular, Arial;
      font-weight: 400;
      color: #FFFFFF;
      width:calc(38%);
      line-height: 30px;
    }
  }
  .inner1{
    .content{
      display: flex;
      flex-direction: column;
      h1{
        text-align: center;
        margin-top: 70px;
      }
      .inner1_text{
        font-size: 13px;
        color: #333;
        line-height: 24px;
        margin: 25px 0 40px;
        padding: 0 110px;
      }
      .left_cell,.right_cell{
        flex: 1;
        // display: flex;
        // justify-content: center;
        padding: 0 0 60px;
      }
      .right_cell{
        img{
          margin: 25px auto !important;
          width: 380px;
          height: 380px;
          display: block;
        }
      }
    }
  }
  .inner2{
    background: #FAFAFA;
    .content{
      display: flex;
      .left_cell,.right_cell{
        flex: 1;
        display: flex;
      }
      h1{
        font-size: 30px;
      }
      img{
        margin:auto 0;
        width: 420px;
        height: 230px;
      }
      .left_cell{
        display: flex;
        flex-direction: column;
        p{
          font-size: 12px;
          line-height: 25px;
          color: black;
          padding: 0 170px 0px 0;
        }
      }
      .right_cell{
        display: flex;
        justify-content: right;
      }
    }
  }

  .inner3{
    margin: 50px 0;
    .content{
      display: flex;
      .left_cell,.right_cell{
        flex: 1;
        display: flex;
        // justify-content: center;
      }
      h1{
        font-size: 30px;
      }
      img{
        margin: 25px 0;
        width: 420px;
        height: 240px;
        // display: block;
      }
      .left_cell{
        display: flex;
        flex-direction: column;
        margin-left: 260px;
        p{
          font-size: 12px;
          line-height: 27px;
          color: black;
        }
      }
    }
  }

  .inner5{
    padding: 0 0 70px 0;
    .inner5-bg{
      width: 100%;
      height: 250px;
      background: linear-gradient(271deg, #9D409B 0%, #160033 100%);
      // padding: 50px 250px;
    }
    h2{
      line-height: 30px;
      margin-bottom: 20px;
    }
    h3{
      font-size: 40px;
      color: white;
      text-align: center;
      line-height:150px;
    }
    .inner5_item{
      // width: 100%;
      display: flex;
      justify-content: space-between;
      margin: -100px 0 0;
      padding: 0px calc((100% - 1100px) / 2) 0;
      font-size: 14px;
      text-align: center;
      line-height: 16px;
      .content{
        flex: 1;
        background: #fff;
        padding: 40px;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 25px;
        box-shadow: 0 0 5px #999;
        img{
          // margin-top: 50px;
          width: 100px;
        }
      }
    }
  }
}
@media only screen and (max-width: 665px){
  .loan_item{
    .inner{
      min-width: calc(100vw) !important;
    }
    .inner0_5{
    background: #171055;
    // background-size: 100% 100%;
    color: white;
    padding: 50px 20px;
    p{
      font-size: 12px;
      width:100%;
      line-height: 30px;

    }
  }
  .inner1{
    h1{
      margin-top: 30px !important;
    }
    h4{
      padding: 0 20px;
    }
    .inner1_text{
      padding: 0 !important;
    }
    .content{
      padding: 0 20px !important;
      display: flex;
      flex-direction: column;
      .left_cell{
        padding: 0;
      }
      .cell_item{
        width: 100% !important;
        height: 100px;
      }
      img{
        margin-top: 0px;
        width: 300px !important;
        height: 300px !important;
        margin: 0 auto ;
      }
    }
  }
  .inner2{
    h4{
      padding: 0 20px;
    }
    .content{
      padding: 0 20px !important;
      display: flex;
      flex-direction: column;
      .left_cell{
        padding: 30px 0;
        h1{
          text-align: center;
          margin-bottom: 20px;
        }
        p{
          padding: 0 !important;
          width: 100% !important;
          margin:  15px 0;
        }
      }
      .right_cell{
        img{
        width: 100% !important;
        }
      }
    }
  }
    .inner3{
      // padding-top: 20px;
      margin: 30px 0;
      h4{
        padding: 0 20px;
      }
      .content{
        padding: 0 20px !important;
        display: flex;
        flex-direction: column;
        .left_cell{
          width: 100%;
          margin-left: 0px;
          padding: 30px 0;
          p{
            padding: 0 !important;
            width: 100% !important;
            // margin:  10px 0;
          }
        }
        .right_cell{
          img{
          width: 100% !important;
          }
        }
      }
    }
  }
  .inner5{
    padding: 0 0 100px 0  !important;
    min-width: calc(100vw - 40px) !important;
    width: calc(100vw - 40px) !important;
    margin: 0 auto !important;
    h3{
      font-size: 18px;
    }
    .inner5_item{
      margin:  -100px 0px !important;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      .content{
        margin: 20px 25px !important;
      }
    }
  }
}
</style>
